.cardContainer {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafefd;

  @screen md {
    padding-left: 40px;
    padding-right: 40px;
    height: 200px;
  }
}

.rainBox {
  left: -350px;
  right: -350px;

  @screen md {
    bottom: -75px;
  }
}

.peopleBox {
  top: 286px;
  left: 84px;
  width: 226px;

  @screen md {
    left: -9px;
    width: 306px;
  }
}

.mainText {
  max-width: 358px;
}

.link {
  width: 100%;

  @screen md {
    width: 260px;
  }
}
