.string span {
  white-space: nowrap;
}
.string del {
  @apply text-dark-gray;
}
.string a {
  @apply font-semibold;
  @apply text-navy;
  text-decoration: underline;
}
