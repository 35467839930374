.leftCloud {
  @apply w-[115px] h-auto absolute rotate-12 z-0 top-xl -left-[45px] sm:w-[200px] sm:top-0 md:w-7xl md:-left-xl lg:rotate-6 lg:top-lg lg:-left-[110px] xl:-left-4xl min-[1440px]:-left-[180px] xl:top-sm xl:z-10 !important;
}

.leftSmallCloud {
  @apply hidden xl:block h-auto absolute z-0 w-[190px] xl:-left-[40px] min-[1440px]:-left-[80px] xl:top-[133px] !important;
}

.balloon {
  @apply hidden xl:block h-auto absolute z-30 w-5xl xl:-left-[10px] min-[1440px]:-left-[80px] xl:top-[120px] animate-bounce !important;
}

.rightCloud {
  @apply w-[115px] h-auto absolute rotate-12 top-2xs -right-[53px] z-10 sm:w-[200px] sm:-right-[80px] md:w-7xl md:-right-[80px] lg:top-sm lg:-right-[110px] min-[1440px]:-right-5xl xl:top-[90px] !important;
}

.sun {
  @apply w-[115px] h-auto absolute z-0 -right-[22px] -top-[40px] animate-fadeIn sm:w-[200px] sm:-top-2xl sm:-right-xs md:right-lg lg:w-7xl lg:-right-xl xl:w-[400px] xl:-right-[160px] xl:-top-[105px] !important;
}

.title {
  @apply w-[345px] sm:w-[400px] md:w-[550px] lg:w-[700px] xl:w-[900px];
}

.subtitle {
  @apply w-[345px] sm:w-[550px] lg:w-[700px] xl:w-[750px];
}
